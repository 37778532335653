<template>
  <span>
    <v-row class="mx-auto">
      <v-col class="pb-0 pt-1" cols="6">
        <input-month
          label="Competência de*"
          rules="required|min:7"
          :selectedMonth="data_de"
          @monthSelected="setDataDe"
        />
      </v-col>
      <v-col class="pb-0 pt-1" cols="6">
        <input-month
          label="Competência até*"
          rules="required|min:7"
          :selectedMonth="data_ate"
          @monthSelected="setDataAte"
        />
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-card class="elevation-2">
          <v-row class="mx-auto">
            <v-col class="pb-0 pt-1" cols="12">
              <v-checkbox
                v-model="selecionar_todos_temas"
                label="Selecionar todos os temas"
              />
            </v-col>
            <v-col
              class="pb-0 pt-1"
              cols="auto"
              v-for="(label, value) in temas_validos"
              :key="value"
            >
              <v-checkbox
                v-model="temas_selecionados"
                :label="label"
                :value="value"
              />
            </v-col>
          </v-row>

          <v-row class="ma-2">
            <v-col cols="auto">
              <v-select
                :items="[
                  { text: 'EQTLINFO', value: 'eqtl_info' },
                  { text: 'Importação Manual', value: 'importador' }
                ]"
                v-model="origem_dados"
                label="Origem dos Dados"
              />
            </v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="info"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <span>Obs: Apenas os temas Consumidores e Reclamação possuem script atualmente, os demais vem da importação manual.</span>
            </v-tooltip>  
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-auto mt-5 mb-5">
      <v-expansion-panels
        v-model="expansion_panel_importacao_indicadores_validacao"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            Importação de Indicadores para Validação
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" v-if="importar_indicadores_para_validacao">
                <v-alert dense outlined type="warning" class="mb-0 py-1 px-1">
                  <strong>Importante:</strong> caso optar pela Importação de
                  Indicadores para Validação, esteja ciente de que quaisquer
                  dados existentes para a Competência serão substituídos.
                </v-alert>
              </v-col>
              <v-col class="pb-0 pt-1" cols="12">
                <v-checkbox
                  v-model="importar_indicadores_para_validacao"
                  label="Deseja importar os indicadores para que possam ficar disponíveis nas ferramentas de validações?"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <validation-provider rules="required">
      <input type="hidden" name="temas" :value="temas_selecionados">
    </validation-provider>
  </span>
</template>

<script>
  export default {
    name: "Alimentadores",
    components: {
      InputMonth: () => import("@/components/general/InputMonth.vue"),
    },
    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        data_de: "",
        data_ate: "",
        temas_validos: {
          QuantidadeDeConsumidores: "Quantidade de Consumidores",
          QuantidadeDeReclamacaoPorFaltaDeEnergia:
            "Quantidade de Reclamação por Falta de Energia",
          Manutencoes: "Manutenções",
          Carregamento: "Carregamento",
        },
        selecionar_todos_temas: false,
        temas_selecionados: [],
        importar_indicadores_para_validacao: false,
        origem_dados: "eqtl_info",
        expansion_panel_importacao_indicadores_validacao: undefined,
      };
    },
    mounted() {
      if (this.$route.params.id) {
        this.expansion_panel_importacao_indicadores_validacao = 0;
      }
    },
    computed: {
      num_temas_selecionados() {
        return this.temas_selecionados.length;
      },
      num_temas_validos() {
        return Object.keys(this.temas_validos).length;
      },
      todos_temas_selecionados() {
        return this.num_temas_selecionados === this.num_temas_validos;
      },
    },
    methods: {
      setDataDe(data) {
        this.data_de = data;
        this.filtersUpdated();
      },
      setDataAte(data) {
        this.data_ate = data;
        this.filtersUpdated();
      },
      filtersUpdated() {
        this.$emit("filtersUpdated", {
          data_de: this.data_de,
          data_ate: this.data_ate,
          temas_selecionados: this.temas_selecionados,
          importar_indicadores_para_validacao: this.importar_indicadores_para_validacao,
          origem_dados: this.origem_dados
        });
      },
    },
    watch: {
      item(newVal) {
        Object.assign(this.$data, newVal.filtros);
      },
      temas_selecionados() {
        this.filtersUpdated();
      },
      importar_indicadores_para_validacao() {
        this.filtersUpdated();
      },
      origem_dados() {
        this.filtersUpdated();
      },
      selecionar_todos_temas() {
        this.temas_selecionados = this.selecionar_todos_temas
          ? Object.keys(this.temas_validos)
          : [];
      },
    },
  };
</script>
